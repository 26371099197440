import { defineStore } from 'pinia';
import { DAL } from 'firework-bll';
import { handleRequest } from '@/utils/request';
import { removeToken, setToken, getToken } from '@/utils/auth';

const UserState = defineStore('user', {
  state: () => {
    return {
      userInfo: {
        UserName: '', //用户名
        LoginType: 0, //账户类型: 0 - 邮箱 1 - google 2 - Facebook
        UserWithThird: 0, //第三方账号ID
        BindAddrType: 0, //绑定类型: 0 -未绑定 1 - metamask 2 - 托管钱包
        WalletAddress: '', //绑定钱包地址
      },
      address: localStorage.getItem('Account'), //钱包地址
      token: getToken(),
      // 钱包地址不一致
      inconsistentWallet: false,
      iwMsg:
        'The wallet address of the current metamask connection is inconsistent with the wallet address that bound to your account.',
    };
  },

  actions: {
    getUserInfo() {
      return new Promise((resolve, reject) => {
        DAL.user
          .getUserInfo()
          .then(handleRequest)
          .then((res: any) => {
            console.log('getUserInfo', res);
            this.userInfo = res;
            this.userInfo.UserName = res.UserName || res.UserWithThirdName;
            resolve(res);
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    },
    clearAccount() {
      this.token = '';
      removeToken();
    },

    // updateAccountInfos(addr: string, type: string) {
    //   console.log(addr, type);
    //   this.address = addr;
    //   localStorage.setItem('Address', addr);
    // },
  },
});

export default UserState;
