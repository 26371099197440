// import { useRouter, useRoute } from 'vue-router';
import useUserStore from '@/store/user';
import { Dialog } from 'vant';
import 'vant/es/dialog/style/less';
import { Notify } from 'vant';
import { getToken } from '@/utils/auth';
import { env } from '@firework/env';

export function allowRequestWeb3(): Promise<boolean> {
  const userStore = useUserStore();
  return new Promise(async resolve => {
    // check list
    // 1. 检查是否安装了web3插件，若未安装，给出提示，❌拒绝链上请求
    // 2. 未登录用户，使用当前连接的钱包✅允许链上请求
    // 3. 已登录用户，未绑定钱包地址，给出提示，❌拒绝链上请求
    // 4. 已登录用户，已绑定钱包，验证当前连接的钱包与绑定的钱包是否一致，若不一致，给出提示，✅允许链上请求
    // 5. 已登录用户，已绑定钱包，钱包地址一致，✅允许链上请求
    // 6. 已登录用户，托管钱包用户，❌拒绝链上请求

    // 1. 检查是否安装了web3插件，若未安装，给出提示，❌拒绝链上请求
    if (typeof window.ethereum !== 'undefined') {
      // console.log('MetaMask is installed!');
    } else {
      console.log('MetaMask is not installed!');
      Dialog.alert({
        title: 'Tips',
        message: 'Please install metamask plugin',
      });
      resolve(false);
    }

    const ethAccounts = await ethereum.request({ method: 'eth_accounts' });
    console.log('ethAccounts', ethAccounts);
    if (ethAccounts.length === 0) {
      console.log('MetaMask is not connect!');
      // Dialog.alert({
      //   title: 'Tips',
      //   message: 'Please install metamask plugin',
      // });
      resolve(false);
    }

    const token = getToken();

    // 2. 未登录用户，使用当前连接的钱包✅允许链上请求
    if (!token) {
      resolve(true);
    }

    // 6. 已登录用户，托管钱包用户，❌拒绝链上请求
    if (token && userStore.userInfo.BindAddrType === 2) {
      resolve(false);
    }

    // 3. 已登录用户，未绑定钱包地址，给出提示，❌拒绝链上请求
    if (token && !userStore.userInfo.WalletAddress) {
      Dialog.confirm({
        title: 'Tips',
        message: 'Please bind a wallet address',
        confirmButtonText: 'Bind now',
      }).then(() => {
        // 跳转绑定钱包地址的页面
        // router.push({
        //   name: 'login',
        //   query: { redirect: router.currentRoute.value.fullPath, act: 'connect' },
        // });
        location.href = env.ssoUrl + `/login?redirect=${location.href}&act=connect`;
      });
      resolve(false);
    }

    // 4. 已登录用户，已绑定钱包，验证当前连接的钱包与绑定的钱包是否一致，若不一致，给出提示，❌拒绝链上请求
    if (token && userStore.userInfo.WalletAddress) {
      const bindAddress = userStore.userInfo.WalletAddress;
      try {
        const [currentAddress] = await ethereum.request({ method: 'eth_requestAccounts' });
        if (bindAddress !== currentAddress) {
          // Dialog.alert({
          //   title: 'Tips',
          //   message: `The wallet address of the current metamask connection is inconsistent with the wallet address that bound to your account. Please switch to the correct wallet in metamask`,
          // });
          userStore.inconsistentWallet = true;
          resolve(true);
        } else {
          userStore.inconsistentWallet = false;
          // 5. 已登录用户，已绑定钱包，钱包地址一致，✅允许链上请求
          resolve(true);
        }
      } catch (error) {
        console.log('error', error);
        resolve(false);
      }
    }
  });
}

export async function initWeb3() {
  // if (!(await allowRequestWeb3())) return;
  if (!window.ethereum || !window.ethereum.on) return;

  ethereum.on('chainChanged', () => {
    Notify({ message: 'Network switch', type: 'primary' });
    location.reload();
    // todo 改为不用刷新页面
  });

  ethereum.on('accountsChanged', (account: string[]) => {
    Notify({ message: 'accounts switch', type: 'primary' });
    // allowRequestWeb3();
    localStorage.setItem('Address', account[0]);
    location.reload();
    // todo 改为不用刷新页面
  });
}

// 是否显示重新登录
export let isRelogin = { show: false };

export function handleRequest(response: any): Promise<any> {
  console.log('handleRequest', response);
  // 未设置状态码则默认成功状态
  // const code = response.code || 200;
  // 获取错误信息
  // const msg = response.codeError;
  // 二进制数据则直接返回
  // if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
  //   return res.data;
  // }
  // if (code === 200) {
  //   return Promise.resolve(response);
  // } else {
  //   response.codeError = msg;
  //   return Promise.reject(response);
  // }

  if (response.code === 401) {
    if (!isRelogin.show) {
      isRelogin.show = true;
      Dialog.confirm({
        title: 'Tips',
        message: 'Your login status has expired, you can stay on this page or log in again.',
        confirmButtonText: 'Re-login',
      })
        .then(() => {
          isRelogin.show = false;
          const userStore = useUserStore();
          userStore.clearAccount();
          location.href = env.ssoUrl + `/login?redirect=${location.href}`;
        })
        .catch(() => {
          isRelogin.show = false;
        });
    }
    // return Promise.reject('无效的会话，或者会话已过期，请重新登录。');
    return Promise.reject('Invalid session, or session has expired, please log in again.');
    // } else if (code === 500) {
    //   // ElMessage({
    //   //   message: msg,
    //   //   type: 'error',
    //   // });
    //   return Promise.reject(new Error(msg));
  } else if (response.code !== 200 || response.success === false) {
    // ElNotification.error({
    //   title: msg,
    // });
    const message = response.msg || response.codeError;
    Dialog.alert({
      title: 'Failed',
      message,
    });
    return Promise.reject(message);
    // return Promise.reject(response.msg || response.codeError);
  } else {
    return Promise.resolve(response.data);
  }
}
