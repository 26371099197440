<script setup lang="ts">
import { GoogleAnalyticsPageClick } from 'firework-analytics';
import Header from './components/Header.vue';
import { useRoute } from 'vue-router';
import { NoticeBar } from 'vant';
import 'vant/es/notice-bar/style/less';
import 'vant/es/notify/style/less';
import useUserStore from '@/store/user';

const userStore = useUserStore();
GoogleAnalyticsPageClick();
const route = useRoute();
</script>

<template>
  <div v-if="route.path !== '/stripe/success'">
    <Header />
  </div>

  <NoticeBar
    class="notice-sticky"
    v-if="userStore.inconsistentWallet"
    wrapable
    :scrollable="false"
    :text="userStore.iwMsg"
  />

  <router-view></router-view>
</template>

<style lang="less">
// 全局样式
body {
  background: #0a1121;
  color: white;
  font-family: ShentoxRegular;
}

@font-face {
  font-family: ShentoxLight;
  src: url(/fonts/Shentox-Light.woff2) format('woff2');
}

@font-face {
  font-family: ShentoxMedium;
  src: url(/fonts/Shentox-Medium.woff2) format('woff2');
}

@font-face {
  font-family: ShentoxRegular;
  src: url(/fonts/Shentox-Regular.woff2) format('woff2');
}

@font-face {
  font-family: ShentoxBold;
  src: url(/fonts/Shentox-SemiBold.woff2) format('woff2');
}

// todo: 升级到vant@4 支持dark模式
// vant-ui 样式覆盖
html:root {
  --van-background-color-light: #0a1121;
  --van-swipe-indicator-size: 16px;
  // --van-loading-spinner-size: 60px;
  --van-font-size-md: 32px;
  --van-notify-padding: 16px;
  --van-notify-line-height: 32px;
  // --van-popup-background-color: #0A1121;
  --van-popup-close-icon-margin: 32px;
  --van-popup-close-icon-size: 40px;
  --van-cell-vertical-padding: 32px;
  --van-cell-horizontal-padding: 40px;
  --van-collapse-item-content-padding: 20px 40px;
  --van-text-color: var(--van-white);
  --van-active-color: #34498f;
  --van-cell-icon-size: 32px;
  // --van-dialog-width: calc(100vw - 80px);
  --van-dialog-width: 80%;
  --van-dialog-header-padding-top: 24px;
  --van-dialog-header-line-height: 100px;
  --van-dialog-font-size: 40px;
  --van-button-loading-icon-size: 40px;
  --van-dialog-message-padding: 40px;
  --van-dialog-message-line-height: 1.5;
  --van-dialog-has-title-message-text-color: var(--van-gray-1);
  --van-dialog-border-radius: 0;
  --van-overlay-background-color: none;
  --van-button-default-font-size: 32px;
  --van-dialog-button-height: 100px;
  --van-button-default-color: var(--van-gray-3);
  // --van-dialog-has-title-message-padding-top: 40px;
  --van-button-plain-background-color: transparent;
  --van-button-normal-padding: 12px 40px;
  --van-button-normal-font-size: 40px;
  --van-button-default-height: auto;
  // --van-button-primary-background-color: #304484;
  // --van-button-primary-border-color: #304484;
  --van-disabled-opacity: 0.6;
  --van-notice-bar-wrapable-padding: 20px 50px;
  --van-notice-bar-line-height: 1.2;
  --van-notice-bar-icon-size: 32px;
  --van-notice-bar-background-color: var(--van-blue);
  --van-notice-bar-text-color: var(--van-white);

  .notice-sticky {
    position: sticky;
    top: 0;
  }

  .van-overlay {
    backdrop-filter: blur(20px) brightness(0.8);
    -webkit-backdrop-filter: blur(20px) brightness(0.8);
  }
}

// 解决ios safari无法锁定滚动的问题
html.lock {
  // overflow: hidden;
  // height: 100%;
  // margin: 0;
  // padding: 0;
  // border: none;
  // position: fixed;
  // height: 100%;
  // width: 100%;
}

body.lock {
  // height: 100%;
  overflow: hidden;
  // width: 100%;
  // position: fixed;
}
</style>
