const __pages_import_0__ = () => import("/src/pages/game.vue");
const __pages_import_1__ = () => import("/src/pages/index.vue");
const __pages_import_2__ = () => import("/src/pages/marketplace.vue");
const __pages_import_3__ = () => import("/src/pages/marketplace/detail.vue");
const __pages_import_4__ = () => import("/src/pages/marketplace/index.vue");
const __pages_import_5__ = () => import("/src/pages/marketplace/market.vue");
const __pages_import_6__ = () => import("/src/pages/marketplace/statistics.vue");
const __pages_import_7__ = () => import("/src/pages/marketplace/storage.vue");
const __pages_import_8__ = () => import("/src/pages/Home/index.vue");
const __pages_import_9__ = () => import("/src/pages/invite/index.vue");
const __pages_import_10__ = () => import("/src/pages/lootbox/[id].vue");
const __pages_import_11__ = () => import("/src/pages/loyalty/index.vue");
const __pages_import_12__ = () => import("/src/pages/stripe/demo.vue");
const __pages_import_13__ = () => import("/src/pages/stripe/success.vue");
const __pages_import_14__ = () => import("/src/pages/loyalty/page/[poolId].vue");
const __pages_import_15__ = () => import("/src/pages/loyalty/rewards/[poolId].vue");
const __pages_import_16__ = () => import("/src/pages/spaceship/configuration/index.vue");
const __pages_import_17__ = () => import("/src/pages/spaceship/detail/index.vue");
const __pages_import_18__ = () => import("/src/pages/spaceship/exploration/index.vue");
const __pages_import_19__ = () => import("/src/pages/spaceship/storehouse/index.vue");
const __pages_import_20__ = () => import("/src/pages/spaceship/upgrade/index.vue");

const routes = [{"name":"game","path":"/game","component":__pages_import_0__,"props":true},{"name":"index","path":"/","component":__pages_import_1__,"props":true},{"path":"/marketplace","component":__pages_import_2__,"children":[{"name":"marketplace-detail","path":"detail","component":__pages_import_3__,"props":true},{"name":"marketplace","path":"","component":__pages_import_4__,"props":true},{"name":"marketplace-market","path":"market","component":__pages_import_5__,"props":true},{"name":"marketplace-statistics","path":"statistics","component":__pages_import_6__,"props":true},{"name":"marketplace-storage","path":"storage","component":__pages_import_7__,"props":true}],"props":true},{"name":"Home","path":"/home","component":__pages_import_8__,"props":true},{"name":"invite","path":"/invite","component":__pages_import_9__,"props":true},{"name":"lootbox-id","path":"/lootbox/:id","component":__pages_import_10__,"props":true},{"name":"loyalty","path":"/loyalty","component":__pages_import_11__,"props":true},{"name":"stripe-demo","path":"/stripe/demo","component":__pages_import_12__,"props":true},{"name":"stripe-success","path":"/stripe/success","component":__pages_import_13__,"props":true},{"name":"loyalty-page-poolId","path":"/loyalty/page/:poolId","component":__pages_import_14__,"props":true},{"name":"loyalty-rewards-poolId","path":"/loyalty/rewards/:poolId","component":__pages_import_15__,"props":true},{"name":"spaceship-configuration","path":"/spaceship/configuration","component":__pages_import_16__,"props":true},{"name":"spaceship-detail","path":"/spaceship/detail","component":__pages_import_17__,"props":true},{"name":"spaceship-exploration","path":"/spaceship/exploration","component":__pages_import_18__,"props":true},{"name":"spaceship-storehouse","path":"/spaceship/storehouse","component":__pages_import_19__,"props":true},{"name":"spaceship-upgrade","path":"/spaceship/upgrade","component":__pages_import_20__,"props":true}];

export default routes;