import { defineStore } from 'pinia';

const GlobalState = defineStore('global', {
  state: () => {
    return {
      isLoading: false,
      userWalletAddress: '',
      userWalletBalance: 0,
    };
  },

  actions: {
    // async index(state, payload) {
    //   // console.log(payload)
    // },

    async show() {
      // console.log(payload)
    },
  },
});

export default GlobalState;
